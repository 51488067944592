import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Skynet.css';

const Skynet = () => {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress === 100) {
          clearInterval(timer);
          setLoading(false);
          navigate('/home');
        }
        return prevProgress + 1;
      });
    }, Math.random() * 90 + 39);

    return () => {
      clearInterval(timer);
    };
  }, [navigate]);

  return (
    <div className="skynet">
      {loading && (
        <div className="loader">
          <div className="loader-bar" style={{ width: `${progress}%` }}></div>
          <div className="loader-text">{progress}%</div>
        </div>
      )}
      <div className="skynet-content">
        <h1>BTFO AI</h1>
        <h2>NEURAL NET-BASED ARTIFICIAL INTELLIGENCE</h2>
        <span>INITIATING CONNECTION TO BLOCKCHAIN</span>
        <p>NEWORDER SYSTEMS CORPORATION</p>
      </div>
    </div>
  );
};

export default Skynet;