import React, { useEffect, useRef } from 'react';
import './CatPopup.css';

const CatPopup = ({ onClose }) => {
  const codeRef = useRef(null);

  useEffect(() => {
    const codeElement = codeRef.current;
    const codeLines = codeElement.innerText.trim().split('\n');
    codeElement.innerText = '';

    let currentLine = 0;
    let currentCharIndex = 0;

    const typeCode = () => {
      if (currentLine === codeLines.length) {
        return;
      }

      const currentLineText = codeLines[currentLine];

      if (currentCharIndex < currentLineText.length) {
        codeElement.innerText += currentLineText.charAt(currentCharIndex);
        currentCharIndex++;
        setTimeout(typeCode, 50);
      } else {
        codeElement.innerText += '\n';
        currentLine++;
        currentCharIndex = 0;
        setTimeout(typeCode, 1000);
      }
    };

    typeCode();
  }, []);

  return (
    <div className="cat-popup">
      <div className="terminal">
        <div className="terminal-header">
          <span>Catsmart Terminal</span>
          <button className="close-button" onClick={onClose}>
            X
          </button>
        </div>
        <div className="terminal-body">
          <pre>
            <code ref={codeRef}>
              {`
function initiateHackingSequence() {
  console.log("Initializing hacking sequence...");
  // Accessing target system...
  // Bypassing security measures...
  // Extracting confidential data...
  console.log("Hacking complete!");
  console.log("Transferring obtained data to secure server...");
  // Encrypting data...
  // Covering tracks...
  console.log("Operation successful!");
}

initiateHackingSequence();
              `}
            </code>
          </pre>
        </div>
      </div>
    </div>
  );
};

export default CatPopup;