import React, { useRef, useEffect } from 'react';
import './RoadmapPopup.css';

const RoadmapPopup = ({ onClose }) => {
    const sliderRef = useRef(null);
    const slideRefs = useRef([]);
  
    useEffect(() => {
      Slider.init(sliderRef.current, sliderRef.current.querySelectorAll('.slide'));
      return () => {
        // Clean up the slider when the component unmounts
        // You may need to add a cleanup method in the Slider object
      };
    }, []);
  
    return (
        <div className="roadmap-popup">
          <button className="close-button" onClick={onClose}>Close</button>
          <h1 className="main-title">Roadmap</h1>
          <div className="slider" ref={sliderRef}>
            <div className="slide" ref={(el) => (slideRefs.current[0] = el)}>
              <div className="slide-container">
                <h2 className="slide-Title">Q1 - 2021</h2>
                <div className="slide-description">
              <ul>
                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                <li>Nunc blandit justo ac dolor lobortis suscipit.</li>
                <li>Et tincidunt lectus porta sit amet.</li>
                <li>Nulla dignissim ligula nec faucibus feugiat.</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="slide" ref={(el) => (slideRefs.current[1] = el)}>
          <div className="slide-container">
            <h2 className="slide-Title">Q2 - 2021</h2>
            <div className="slide-description">
              <ul>
                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                <li>Nunc blandit justo ac dolor lobortis suscipit.</li>
                <li>Et tincidunt lectus porta sit amet.</li>
                <li>Nulla dignissim ligula nec faucibus feugiat.</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="slide" ref={(el) => (slideRefs.current[2] = el)}>
          <div className="slide-container">
            <h2 className="slide-Title">Q3 - 2021</h2>
            <div className="slide-description">
              <ul>
                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                <li>Nunc blandit justo ac dolor lobortis suscipit.</li>
                <li>Et tincidunt lectus porta sit amet.</li>
                <li>Nulla dignissim ligula nec faucibus feugiat.</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="slide active" ref={(el) => (slideRefs.current[3] = el)}>
  <div className="slide-container">
    <h2 className="slide-Title">Q4 - 2021</h2>
    <div className="slide-description">
      <ul>
        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
        <li>Nunc blandit justo ac dolor lobortis suscipit.</li>
        <li>Et tincidunt lectus porta sit amet.</li>
        <li>Nulla dignissim ligula nec faucibus feugiat.</li>
      </ul>
    </div>
  </div>
</div>
        <div className="slide" ref={(el) => (slideRefs.current[4] = el)}>
          <div className="slide-container">
            <h2 className="slide-Title">Q1 - 2022</h2>
            <div className="slide-description">
              <ul>
                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                <li>Nunc blandit justo ac dolor lobortis suscipit.</li>
                <li>Et tincidunt lectus porta sit amet.</li>
                <li>Nulla dignissim ligula nec faucibus feugiat.</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="slide" ref={(el) => (slideRefs.current[5] = el)}>
          <div className="slide-container">
            <h2 className="slide-Title">Q2 - 2022</h2>
            <div className="slide-description">
              <ul>
                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                <li>Nunc blandit justo ac dolor lobortis suscipit.</li>
                <li>Et tincidunt lectus porta sit amet.</li>
                <li>Nulla dignissim ligula nec faucibus feugiat.</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="slide" ref={(el) => (slideRefs.current[6] = el)}>
          <div className="slide-container">
            <h2 className="slide-Title">Q3 - 2022</h2>
            <div className="slide-description">
              <ul>
                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                <li>Nunc blandit justo ac dolor lobortis suscipit.</li>
                <li>Et tincidunt lectus porta sit amet.</li>
                <li>Nulla dignissim ligula nec faucibus feugiat.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

var Slider = function() {
    var total, slide, slider, sliderWidth, increment = 120;
  
    var on = function(sliderElement, slideElements) {
      slider = sliderElement;
      slide = Array.from(slideElements);
      sliderWidth = slider.offsetWidth;
      total = slide.length;
      position();
    }
  
    var position = function() {
      var sign, half = slide.findIndex((el) => el.classList.contains('active')), x = 0, z = 0, zindex, scaleX = 1.3, scaleY = 1.3, transformOrigin;
  
      slide.forEach(function(element, index) {
        scaleX = scaleY = 1;
        transformOrigin = sliderWidth / 2;
  
        if(index < half) {
          sign = 1;
          zindex = index + 1;
          x = sliderWidth / 2 - increment * (half - index + 1);
          z = -increment * (half - index + 1);
        } else if(index > half) {
          sign = -1;
          zindex = total - index;
          x = sliderWidth / 2 + increment * (index - half + 1);
          z = -increment * (index - half + 1);
        } else {
          sign = 0;
          zindex = total;
          x = sliderWidth / 2;
          z = 1;
          scaleX = scaleY = 1.2;
          transformOrigin = 'initial';
        }
  
        element.style.transform = 'translate3d(' + calculateX(x, sign, 300) + 'px, 0,' + z + 'px) scale3d(' + scaleX + ',' + scaleY + ', 1)';
        element.style.zIndex = zindex;
        element.style.transformOriginX = transformOrigin;
      });
    };
  
    var calculateX = function(position, sign, width) {
      switch(sign) {
        case 1:
        case 0: return position - width / 2;
        case -1: return position - width / 2;
        default: return position;
      }
    }
  
    var recalculateSizes = function() {
      sliderWidth = slider.offsetWidth;
      position();
    }
  
    var clickedImage = function(event) {
        var clickedSlide = event.target.closest('.slide');
        if (clickedSlide) {
          slide.forEach((el) => el.classList.remove('active'));
          clickedSlide.classList.add('active');
          position();
        }
      }
  
    var addEvents = function() {
      window.addEventListener('resize', recalculateSizes);
      slider.addEventListener('click', clickedImage);
    }
  
    return {
      init: function(sliderElement, slideElements) {
        on(sliderElement, slideElements);
        addEvents();
      }
    };
  }();
  
  export default RoadmapPopup;